import { Card, CardContent } from '@mui/material';
import { forwardRef } from 'react';

export const PUICardSizes = [
  'standard',
  'standard-without-padding',
  'small',
  'large',
] as const;
export type PUICardSizes = typeof PUICardSizes[number];

export const PUICardVariants = ['default', 'accent'] as const;
export type PUICardVariants = typeof PUICardVariants[number];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PUICardSimpleProps extends React.PropsWithChildren {
  size?: PUICardSizes;
  variant?: PUICardVariants;
  // TODO: extract className to a separate interface/type declaration to areuse it in other components;
  className?: string;
}

export const getCardSpacings = (
  size: PUICardSizes
): {
  borderRadius: number;
  padding: number;
} => {
  switch (size) {
    case 'standard':
      return {
        borderRadius: 0,
        padding: 6,
      };
    case 'standard-without-padding':
      return {
        borderRadius: 0,
        padding: 0,
      };
    case 'small':
      return {
        borderRadius: 0,
        padding: 4,
      };
    case 'large':
      return {
        borderRadius: 4,
        padding: 8,
      };
  }
};

export const PUICardSimple = forwardRef<HTMLDivElement, PUICardSimpleProps>(
  function Component(
    {
      children,
      size = 'standard',
      variant = 'default',
      className,
      ...restProps
    }: PUICardSimpleProps,
    ref
  ) {
    const spacings = getCardSpacings(size);

    return (
      <Card
        ref={ref}
        className={className}
        variant="outlined"
        {...restProps}
        sx={{
          borderRadius: spacings?.borderRadius,
          ...(variant === 'accent' && {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }),
        }}
      >
        <CardContent
          sx={{
            width: '100%',
            padding: spacings?.padding,
            '&:last-child': {
              paddingBottom: spacings?.padding,
            },
          }}
        >
          {children}
        </CardContent>
      </Card>
    );
  }
);
