import { useCallback } from 'react';
import { isToasterVariant, useToasters } from 'shared-components';

export const useFetchMessage = () => {
  const { enqueueToaster } = useToasters();

  return useCallback(
    function showFetchMessage(message: string, messageType: string) {
      if (isToasterVariant(messageType)) {
        enqueueToaster(messageType, message);
      }
    },
    [enqueueToaster]
  );
};
