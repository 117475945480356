/**
 * These are the default values of the flags.
 * We are currently using LaunchDarkly service to manage the feature flags which automatically normalizes the keys of the flags.
 * When you retrieve the feature flag values from LD SDK they get automatically converted to the camelCase format.
 * Nevertheless, the flag keys in the object below must be in their original form as known to LaunchDarkly, rather than in their camelCased form.
 * This requirement implies that we should should always use camelCase format in both places: LD Service and defaultFeatureFlags object. That way
 * we can guarantee that TypeScript correctly maps all feature flag keys to their LaunchDarkly counterpart.
 * For example:
 * 1) BAD! ಠ_ಠ
 * Flag key stored in LD service: SHOW_RECORD_BID_BUTTON
 * Flag key in the defaultFeatureFlags object: SHOW_RECORD_BID_BUTTON
 * Normalized Flag Key in the app: showRecordBidButton
 * WHy is it bad? SHOW_RECORD_BID_BUTTON key provided by TS compiler doesn't match showRecordBidButton provided by LD SDK.
 *
 * 2) BAD! ಠ_ಠ
 * Flag Key stored in LD service: show-record-bid-button
 * Flag key in the defaultFeatureFlags object: show-record-bid-button
 * Normalized Flag Key in the app: showRecordBidButton
 * WHy is it bad? show-record-bid-button key provided by TS compiler doesn't match showRecordBidButton provided by LD SDK.
 *
 * 3) BAD! ¯\_(ツ)_/¯ - the tricky one!
 * Flag Key stored in LD service: showDATLabels
 * Flag key in the defaultFeatureFlags object: showDATLabels
 * Normalized Flag Key in the app: showDatLabels ("Dat" instead of "DAT")
 * WHy is it bad? showDATLabels key provided by TS compiler doesn't match showDatLabels provided by LD SDK.
 *
 * 4) GOOD ٩(^‿^)۶
 * Flag Key stored in LD service: showRecordBidButton
 * Flag key in the defaultFeatureFlags object: showRecordBidButton
 * Normalized Flag Key in the app: showRecordBidButton
 * WHy is it good? showRecordBidButton key provided by TS compiler matchs showRecordBidButton provided by LD SDK. All happy!
 *
 * 5) GOOD ٩(^‿^)۶
 * Flag Key stored in LD service: show-record-bid-button
 * Flag key in the defaultFeatureFlags object: show-record-bid-button
 * Normalized Flag Key in the app: show-record-bid-button
 * WHy is it good? show-record-bid-button key provided by TS compiler matchs show-record-bid-button provided by LD SDK. All happy!
 *
 * We use the default values below for two main purposes:
 *  a) provide TS types for useFeatureFlag() hook to enable autocompletion on the resulting object.
 *  b) set default values for the FeatureFlagsProvider component so it returns these values in case LaunchDarkly service fails for any reason.
 * !!! WARNING !!! Mismatch between the keys of defaultFeatureFlags object and LaunchDarkly keys can be a SOURCE OF NOT WORKING FLAGS.
 */
export const defaultFeatureFlags = {
  showDatLabels: true,
  prefetchCapacityTable: true,
  showRecordBidButton: false,
  showDatSurvey: true,
  'show-bid-history': false,
  'show-codriver': false,
  'show-dat-rate-type-switch': false,
  'enable-side-tab-style-of-dat-survey': false,
  'enable-advantage-page-v-2': false,
  'show-side-navigation': false,
  'show-real-time-rating-rules-configuration': false,
  'show-real-time-rating-rules-analytics': false,
};
