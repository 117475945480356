import { Collapse, Alert, AlertProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export type PUIAlertProps = PropsWithChildren<{
  open?: boolean;
  type?: AlertProps['severity'];
  onClose?: AlertProps['onClose'];
  className?: string;
}>;

export const PUIAlert = ({
  open = true,
  type = 'info',
  children,
  ...alertProps
}: PUIAlertProps) => {
  const errorStyle = {
    backgroundColor: 'error.light',
    '& .MuiAlert-icon': {
      color: 'error.dark',
    },
  };
  return (
    <Collapse in={open} unmountOnExit>
      <Alert
        severity={type}
        {...alertProps}
        sx={{ ...(type === 'error' && errorStyle) }}
      >
        {children}
      </Alert>
    </Collapse>
  );
};
