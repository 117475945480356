import { IconButton } from '@mui/material';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { CloseIcon } from 'shared-components/PUIIcons';
import {
  SnackbarKey,
  SnackbarProvider,
  TransitionHandlerProps,
  useSnackbar,
} from 'notistack';

export const TOASTER_AUTO_HIDE_DURATION_MS = 10000;

export const PUIToasterProvider = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={TOASTER_AUTO_HIDE_DURATION_MS}
      hideIconVariant={true}
      css={(theme) => ({
        '& > .SnackbarItem-message': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        '&.SnackbarItem-variantError': {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.error.contrastText,
        },
      })}
    >
      {children}
    </SnackbarProvider>
  );
};

export const useToasters = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onCloseHandler: TransitionHandlerProps['onClose'] = useCallback(
    (event, reason, snackbarKey) => {
      if (reason === 'clickaway') {
        return;
      }

      closeSnackbar(snackbarKey);
    },
    [closeSnackbar]
  );

  const getAction: SnackbarAction = useCallback(
    (snackbarKey: SnackbarKey) => (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(event) => onCloseHandler(event, 'instructed', snackbarKey)}
        >
          <CloseIcon />
        </IconButton>
      </>
    ),
    [onCloseHandler]
  );

  return {
    enqueueToaster: useCallback(
      (
        variant: PUIToasterVariantType,
        message: string | React.ReactNode,
        action: SnackbarAction = getAction
      ): PUIToasterKey => {
        return enqueueSnackbar(message, { variant, action });
      },
      [enqueueSnackbar, getAction]
    ),
    closeToaster: useCallback(
      (toasterKey: PUIToasterKey) => closeSnackbar(toasterKey),
      [closeSnackbar]
    ),
    closeAllToasters: useCallback(() => closeSnackbar(), [closeSnackbar]),
  };
};

export const TOASTER_VARIANT_TYPES = [
  'default',
  'error',
  'success',
  'warning',
  'info',
] as const;

export type PUIToasterVariantType = typeof TOASTER_VARIANT_TYPES[number];

export const isToasterVariant = (
  variant: string
): variant is PUIToasterVariantType => {
  return TOASTER_VARIANT_TYPES.includes(variant as PUIToasterVariantType);
};

export type PUIToasterCloseReason =
  | 'timeout'
  | 'clickaway'
  | 'maxsnack'
  | 'instructed';

export type PUIToasterKey = number | string;

export type SnackbarAction =
  | React.ReactNode
  | ((key: SnackbarKey) => React.ReactNode);

type PUIToasterProps = PropsWithChildren<{
  className?: string;
  onClose?: (
    event: React.SyntheticEvent<unknown, Event> | null,
    reason: PUIToasterCloseReason
  ) => void;
  open?: boolean;
}>;

export const PUIToaster = ({
  open = true,
  onClose,
  children,
  className,
}: PUIToasterProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCloseHandler: TransitionHandlerProps['onClose'] = (
    event,
    reason,
    snackbarKey
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackbar(snackbarKey);
  };

  const getAction: SnackbarAction = (snackbarKey) => (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={(event) => onCloseHandler(event, 'instructed', snackbarKey)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  useEffect(() => {
    const snackbarKey =
      open &&
      enqueueSnackbar(children, { action: getAction, onClose, className });

    return () => {
      snackbarKey && closeSnackbar(snackbarKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, open]);

  return <></>;
};
