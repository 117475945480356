import { Button, ButtonProps } from '@mui/material';
import { HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react';

export type PUIButtonProps = PropsWithChildren &
  HTMLAttributes<HTMLButtonElement> & {
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  } & Pick<
    ButtonProps,
    | 'color'
    | 'variant'
    | 'disabled'
    | 'startIcon'
    | 'endIcon'
    | 'fullWidth'
    | 'href'
    | 'size'
    | 'className'
    | 'type'
  >;

export const PUIButton = ({
  children,
  onClick,
  className,
  ...restProps
}: PUIButtonProps) => {
  return (
    <Button onClick={onClick} className={className} {...restProps}>
      {children}
    </Button>
  );
};
