import { Container, ContainerProps } from '@mui/material';
import { PropsWithChildren, forwardRef } from 'react';

type PUIContainerProps = PropsWithChildren & {
  fullWidth?: boolean;
  component?: React.ElementType;
} & Pick<
    ContainerProps,
    'className' | 'disableGutters' | 'fixed' | 'maxWidth' | 'style'
  >;

export const PUIContainer = forwardRef<HTMLDivElement, PUIContainerProps>(
  function PUIContainerInt({ fullWidth, ...props }: PUIContainerProps, ref) {
    return (
      <Container
        ref={ref}
        {...props}
        {...(fullWidth && {
          sx: (theme) => ({
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            // TODO: it feels like a pretty hacky way of overriding paddings.
            // This code is based on the source code of the component:
            // https://github.com/mui/material-ui/blob/381942d9359eb26a0e83777eaa392e300025befb/packages/mui-system/src/Container/createContainer.tsx#L85
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(6),
              paddingRight: theme.spacing(6),
            },
          }),
          maxWidth: false,
        })}
      />
    );
  }
);
