import { BoxProps } from '@mui/material';

interface PUITabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  tabPanelProps?: BoxProps;
}

export function PUITabPanel({ children, value, index }: PUITabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
}
