import { Toolbar, ToolbarProps } from '@mui/material';
import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

type PUIToolbarInternalProps = PropsWithChildren &
  Pick<ToolbarProps, 'onClick' | 'className' | 'disableGutters'> &
  HTMLAttributes<HTMLDivElement>;

const PUIToolbarInternal = (
  { children, onClick, className, ...restProps }: PUIToolbarInternalProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Toolbar ref={ref} onClick={onClick} className={className} {...restProps}>
      {children}
    </Toolbar>
  );
};

export const PUIToolbar = forwardRef<HTMLDivElement, PUIToolbarInternalProps>(
  PUIToolbarInternal
);
