import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { PropsWithChildren } from 'react';

type PUITooltipProps = PropsWithChildren & {
  className?: string;
  title: TooltipProps['title'];
  children: TooltipProps['children'];
  placement?: TooltipProps['placement'];
};

export const PUITooltip = ({ ...props }: PUITooltipProps) => {
  return <Tooltip enterDelay={600} {...props} />;
};
