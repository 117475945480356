import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createHttpClient, HttpClientType } from 'httpClient';
import {
  PropsWithChildren,
  useContext,
  useState,
  createContext,
  useMemo,
} from 'react';
import { PUIDefaultLoader } from 'shared-components';
import { getUserIdentityInfo, UserIdentity } from 'components/userIdentityInfo';
import * as Sentry from '@sentry/nextjs';

interface UserInfo {
  accessToken: string | null;
  identity: UserIdentity | null;
  loggedOut: boolean;
}

export interface ApiClientContextType {
  // we initialize the client asynchronously so it's not available immedialy and therefore can be null
  gatewayClient: HttpClientType;
  session: {
    user: UserInfo;
    // setUserInfo: (userInfo: UserInfo) => void;
    setAuthToken: (token: string) => void;
    logout: (forceLogout: boolean) => void;
  };
}

export const ApiClientContext = createContext<ApiClientContextType>({
  // Default Context that should be available in the react tree
  // if context consumers are not wrapped into the context provider.
  gatewayClient: {} as HttpClientType,
  session: {
    user: {
      accessToken: null,
      identity: null,
      loggedOut: false,
    },
    setAuthToken: (): void => {
      /* do nothing */
    },
    logout: (): void => {
      /* do nothing */
    },
  },
});

export const initializeGatewayClient = async (): Promise<HttpClientType> => {
  try {
    const client = await createHttpClient();
    return client;
  } catch (error) {
    console.error('unable to initialize Gateway HTTP Client', error);
    throw Error('could not initialize gateway client');
  }
};

const readTokenFromStorage = (): string | null => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem('accessToken') ?? null;
};

const saveTokenInStorage = (token: string | null) => {
  if (token === null) {
    localStorage.removeItem('accessToken');
  } else {
    return localStorage.setItem('accessToken', token);
  }
};

export const ApiClientProvider = ({ children }: PropsWithChildren) => {
  const persistedToken = readTokenFromStorage();
  const user = persistedToken ? getUserIdentityInfo(persistedToken) : null;
  const reactQueryClient = useQueryClient();

  const [userInfo, setUserInfo] = useState<UserInfo>({
    accessToken: persistedToken,
    identity: user,
    loggedOut: false,
  });

  if (userInfo.identity) {
    const { company_id, company_key, company_user_id, team_name, user_id } =
      userInfo.identity;

    Sentry.setUser({
      id: user_id ? String(user_id) : undefined,
      ...{
        company_id,
        company_key,
        company_user_id,
        team_name,
      },
    });
  } else {
    Sentry.setUser(null);
  }

  // Sync up local token value with persisted value
  if (userInfo.accessToken != persistedToken)
    saveTokenInStorage(userInfo.accessToken);

  const { data: gatewayClient, isLoading } = useQuery(
    ['gateway-client'],
    initializeGatewayClient
  );

  const session = useMemo(
    () => ({
      user: userInfo,
      setAuthToken: (token: string) => {
        setUserInfo({
          accessToken: token,
          identity: token ? getUserIdentityInfo(token) : null,
          loggedOut: false,
        });
      },
      logout: (forceLogout = false) => {
        saveTokenInStorage(null);
        setUserInfo({
          accessToken: null,
          identity: null,
          loggedOut: forceLogout,
        });
        if (gatewayClient) {
          // removing jwt token from http client
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { 'jwt-token': _, ...defaultHeaders } =
            gatewayClient.getDefaultHeaders();
          gatewayClient.setDefaultHeaders(defaultHeaders);
        }
        void reactQueryClient.invalidateQueries();
      },
    }),
    [gatewayClient, userInfo, reactQueryClient]
  );

  const apiClient = useMemo(() => {
    if (gatewayClient) return { gatewayClient, session };
  }, [gatewayClient, session]);

  if (isLoading || typeof apiClient === 'undefined') {
    return <PUIDefaultLoader />;
  }

  // set access token to api client if we have one
  if (userInfo.accessToken !== null)
    apiClient.gatewayClient.setDefaultHeaders({
      'jwt-token': userInfo.accessToken,
    });

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const useApiClient = () => {
  return useContext(ApiClientContext);
};
