import { useFlags } from 'launchdarkly-react-client-sdk';
import { defaultFeatureFlags } from 'config/defaultFeatureFlags';

export const useFeatureFlags = () => {
  const flags = useFlags<typeof defaultFeatureFlags>();
  if (process.env.NODE_ENV === 'development') {
    return {
      ...flags,
      // TODO: we should be able to replace these hadrcoded values
      // below with Dev environment in LaunchDarkly.
      'enable-advantage-page-v-2': true,
      'show-side-navigation': true,
      showRecordBidButton: true,
      'show-real-time-rating-rules-configuration': true,
      'show-real-time-rating-rules-analytics': true,
      'show-codriver': true,
    };
  }
  return flags;
};
