import { IconButton, IconButtonProps } from '@mui/material';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

type PUIIconButtonProps = PropsWithChildren &
  Pick<
    IconButtonProps,
    'color' | 'disabled' | 'size' | 'className' | 'onClick' | 'edge' | 'role'
  >;

/*
          onClick={onLogoClick}
          size="large"
          edge="start"
          color="inherit"
          role="navigation"
          aria-label="Main"
*/

const PUIIconButtonInternal = (
  { children, onClick, className, ...restProps }: PUIIconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <IconButton
      ref={ref}
      onClick={onClick}
      className={className}
      {...restProps}
    >
      {children}
    </IconButton>
  );
};

export const PUIIconButton = forwardRef<HTMLButtonElement, PUIIconButtonProps>(
  PUIIconButtonInternal
);
