import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

export type SelectOptionType<T> = {
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  label?: string;
  value?: T;
};

export interface PUISelectProps<
  T extends string,
  Multiple extends boolean = false
> {
  value?: Multiple extends undefined | false ? T : Array<T>;
  onChange?: (value: Multiple extends undefined | false ? T : Array<T>) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  selectOptions?: SelectOptionType<T>[];
  multiple?: Multiple;
  fullWidth?: boolean;
}

export function PUISelect<T extends string, Multiple extends boolean = false>({
  value,
  onChange,
  helperText,
  label,
  disabled,
  selectOptions,
  error,
  multiple,
  fullWidth = true,
}: PUISelectProps<T, Multiple>) {
  // TODO: optimize prop values and memoize callbacks
  return (
    <FormControl fullWidth={fullWidth} variant="standard" error={error}>
      {label && <InputLabel id={`select-${label}-label`}>{label}</InputLabel>}
      <Select
        labelId={`select-${label ?? 'unknown'}-label`}
        id={`select-${label ?? 'unknown'}`}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onChange={(event) => {
          return (
            onChange &&
            onChange(
              // TODO: Investigate why Select adds an additional string type as even.target.value.
              // It seems like there is a bug in Select type declarations. Type assertions is neccessary here until we figure this out.
              event.target.value as Multiple extends undefined | false
                ? T
                : Array<T>
            )
          );
        }}
      >
        {selectOptions?.map(
          ({ disabled, icon, iconPosition, label, value }) => (
            <MenuItem
              key={`key-${value ?? 'unknown'}`}
              value={value}
              disabled={disabled}
            >
              <>
                {' '}
                {icon && iconPosition !== 'end' && icon}
                {label === '' ? '' : label || value}
                {icon && iconPosition === 'end' && icon}
              </>
            </MenuItem>
          )
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
