import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PropsWithChildren } from 'react';

interface CustomDialogTitleProps {
  children: React.ReactNode;
  hasCloseButton: boolean;
  onClose?: () => void;
}

export const DefaultDialogTitle = ({
  children,
  hasCloseButton,
  onClose,
  ...props
}: CustomDialogTitleProps) => {
  return (
    <DialogTitle {...props}>
      {children}
      {hasCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: `absolute`,
            right: 16,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export interface PUIDialogProps extends PropsWithChildren {
  isLoading?: boolean;
  title?: string;
  closeOnCancelOnly?: boolean;
  onClose?: () => void;
  isOpen?: boolean;
  hasCloseButton?: boolean;
  actions?: React.ReactNode;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const PUIDialog = ({
  children,
  isLoading,
  title,
  closeOnCancelOnly,
  onClose,
  isOpen = true,
  hasCloseButton = true,
  actions,
  className,
  size = 'md',
}: PUIDialogProps) => {
  return (
    <Dialog
      maxWidth={size}
      onClose={(_, reason) => {
        if (
          closeOnCancelOnly &&
          ['escapeKeyDown', 'backdropClick'].includes(reason)
        ) {
          return;
        }
        onClose && onClose();
      }}
      open={isOpen}
      className={className}
      fullWidth
    >
      {title && (
        <DefaultDialogTitle hasCloseButton={hasCloseButton} onClose={onClose}>
          {title}
        </DefaultDialogTitle>
      )}
      <Collapse in={isLoading} unmountOnExit mountOnEnter>
        <LinearProgress />
      </Collapse>

      {children && <DialogContent dividers>{children}</DialogContent>}

      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
