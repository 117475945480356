import { PropsWithChildren } from 'react';
import { FallbackProps, ErrorBoundary } from 'react-error-boundary';
import { PUIButton } from 'shared-components/PUIButton';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

export const isAxiosAuthorizationError = (
  error: Error | AxiosError
): boolean => {
  const unauthorizedCode = '401';
  console.warn(
    'axios error',
    axios.isAxiosError(error) ? error : 'non axios error'
  );
  return (
    axios.isAxiosError(error) &&
    String(error.response?.status) === unauthorizedCode
  );
};

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div className="center">
      <h3 css={{ color: 'palette.error' }}>{error.message}</h3>
      <PUIButton variant="contained" color="info" onClick={resetErrorBoundary}>
        Reload
      </PUIButton>
    </div>
  );
};

export type PUILoaderBoundaryProps = PropsWithChildren;

export const PUILoaderBoundary = ({
  children,
}: // loadingFallback = DefaultLoader,
PUILoaderBoundaryProps) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={reset}>
      {/* <Suspense fallback={loadingFallback}> */}
      {children}
      {/* </Suspense> */}
    </ErrorBoundary>
  );
};
