import { useMemo, useState } from 'react';

export const useToggle = () => {
  const [booleanState, setBooleanState] = useState(false);

  const toggleOn = () => {
    setBooleanState(true);
  };

  const toggleOff = () => {
    setBooleanState(false);
  };

  return useMemo(
    () => ({
      isOpen: booleanState,
      toggleOn,
      toggleOff,
    }),
    [booleanState]
  );
};
