import 'styles/font-face.css';
import 'styles/global.css';
import 'basscss';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import type { NextComponentType, NextPage } from 'next';
import {
  PUIAppLayout,
  PUIThemeProvider,
  PUIToasterProvider,
} from 'shared-components';
import { ApiClientProvider } from 'components/ApiClientProvider';
import { PUILoaderBoundary } from 'shared-components/PUILoaderBoundary/PUILoaderBoundary';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthGuard } from 'components/AuthGuard';
import { ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import { parseSearchQuery, SearchParams } from 'components/SearchForm/search';
import * as Sentry from '@sentry/nextjs';
import { FetchClientProvider } from 'components/FetchClientProvider';
import Script from 'next/script';
import { CONFIG } from 'config/env.config';
import { FeatureFlagsProvider } from 'components/FeatureFlagsProvider';
import { StyledEngineProvider } from '@mui/material/styles';

type LayoutGetter = (page: ReactElement) => ReactElement;

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: LayoutGetter;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

export const pageView = (url: string) => {
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
  });
};

const AdvantageApp: NextComponentType<
  AppContext,
  AppInitialProps,
  AppPropsWithLayout
> = ({ Component, pageProps }: AppPropsWithLayout) => {
  // if available, use page level layout. Read more about this approach here: https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  // const getLayout = Component.getLayout ?? defaultGetLayout;
  const isTagManagerEnabled = Boolean(CONFIG.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID);
  const router = useRouter();
  const searchParams: SearchParams = parseSearchQuery(router.query);
  Sentry.configureScope(function (scope) {
    Object.entries(searchParams).forEach((parameter) =>
      scope.setTag(`search.${parameter[0]}`, JSON.stringify(parameter[1]))
    );
  });

  useEffect(() => {
    if (isTagManagerEnabled) {
      router.events.on('routeChangeComplete', pageView);
      return () => {
        router.events.off('routeChangeComplete', pageView);
      };
    }
  }, [router.events, isTagManagerEnabled]);

  return (
    <>
      {/* <!-- Google Tag Manager --> */}
      {isTagManagerEnabled && (
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${CONFIG.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
          }}
        />
      )}
      {/* <!-- End Google Tag Manager --> */}
      <StyledEngineProvider injectFirst>
        <PUIThemeProvider>
          <PUIToasterProvider>
            <PUIAppLayout>
              <FetchClientProvider>
                <ApiClientProvider>
                  <FeatureFlagsProvider>
                    <PUILoaderBoundary>
                      <AuthGuard>
                        <Component {...pageProps} />
                      </AuthGuard>
                    </PUILoaderBoundary>
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                    />
                  </FeatureFlagsProvider>
                </ApiClientProvider>
              </FetchClientProvider>
            </PUIAppLayout>
          </PUIToasterProvider>
        </PUIThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default AdvantageApp;
