import { useTheme } from '@mui/material';
import { PUITheme } from 'shared-components/PUITheme/themes';

/**
 * Hook for getting Parade's Design System theme object
 * @returns default theme object
 */
export function usePUITheme(): PUITheme {
  return useTheme(); // It's just a simple wrapper around MUI theme for now.
}
