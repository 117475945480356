import Image, { ImageProps } from 'next/image';

type PUIImageProps = { alt: string } & Pick<
  ImageProps,
  'className' | 'src' | 'width' | 'height' | 'alt'
>;

export const PUIImage = ({ alt, ...props }: PUIImageProps) => {
  return <Image alt={alt} {...props} />;
};
