import { useTheme } from '@emotion/react';
import { AppBar, AppBarProps } from '@mui/material';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';

export type PUINavBarProps = PropsWithChildren & {
  // TODO: extract className to a separate interface/type declaration to areuse it in other components;
  className?: string;
  toolbarContent?: ReactNode;
  vertical?: boolean;
} & Pick<AppBarProps, 'position' | 'onClick' | 'color'>;

/**
 * Top Level Navigation panel
 * @param param0 Component props
 * @returns
 */
export const PUINavBar = forwardRef(function PUINavBar(
  {
    children,
    className,
    toolbarContent,
    vertical,
    color,
    ...restProps
  }: PUINavBarProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const theme = useTheme();
  return (
    <AppBar
      ref={ref}
      className={className}
      color={color}
      {...restProps}
      sx={{
        zIndex: theme.zIndex.drawer,
        ...(color === 'default' && {
          backgroundColor: theme.palette.background.default,
        }),
      }}
      elevation={0}
    >
      <div
        css={{
          display: 'flex',
          height: '100%',
          ...(vertical
            ? {
                flexDirection: 'column',
                borderRight: `1px solid ${theme.palette.divider}`,
                alignItems: 'center',
              }
            : {
                flexDirection: 'row',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }),
        }}
      >
        {toolbarContent}
      </div>
      {children}
    </AppBar>
  );
});
