import { CONFIG } from 'config/env.config';
import { defaultFeatureFlags } from 'config/defaultFeatureFlags';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { UserIdentity } from 'components/userIdentityInfo';

export const setupLaunchDarkly = async (user: UserIdentity | null) => {
  console.info('Setting up LaunchDarkly Client...');

  const LDProvider = await asyncWithLDProvider({
    clientSideID: CONFIG.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    // No user -> no context.
    ...(user && {
      context: {
        kind: 'user',
        name: user.company_key,
        key: String(user.company_id ?? ''),
        email: user.user_email,
        user_id: user.user_id,
        company_user_id: user.company_user_id,
        _meta: {
          privateAttributes: ['email'],
        },
      },
    }),
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      streaming: true,
      bootstrap: 'localStorage',
    },
    flags: {
      ...defaultFeatureFlags,
    },
  });

  return LDProvider;
};
