import Divider, { DividerProps } from '@mui/material/Divider';

type PUIDividerProps = Pick<
  DividerProps,
  'className' | 'orientation' | 'flexItem'
>;

export const PUIDivider = (props: PUIDividerProps) => {
  return <Divider {...props} />;
};
