import { BoxProps } from '@mui/material';
import { forwardRef } from 'react';

interface PUITabPanelV2Props {
  children: React.ReactNode;
  value?: number;
  index?: number;
  tabPanelProps?: BoxProps;
}

export const PUITabPanelV2 = forwardRef<HTMLDivElement, PUITabPanelV2Props>(
  function PUITabPanelV2({ children }, ref) {
    return (
      <div role="tabpanel" ref={ref}>
        {children}
      </div>
    );
  }
);
