import React, { useCallback } from 'react';
import { Tab, Tabs } from '@mui/material';

interface PUITabsProps {
  tabs: {
    label: string;
    renderContent: () => React.ReactNode;
  }[];
  className?: string;
  value: number;
  onChange?: (newValue: number) => void;
}

export const PUITabs = ({ tabs, className, value, onChange }: PUITabsProps) => {
  const onChangeHandler = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
      onChange && onChange(newValue);
    },
    [onChange]
  );

  return (
    <Tabs value={value} className={className} onChange={onChangeHandler}>
      {tabs.map(({ label }) => (
        <Tab label={label} key={label} />
      ))}
    </Tabs>
  );
};
