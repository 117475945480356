import React from 'react';
import { ThemeProvider } from '@mui/material';
import { createAdvantageTheme, PUIPaletteMode } from './themes';

export interface PUIThemeProps extends React.PropsWithChildren {
  mode?: PUIPaletteMode;
}

export const PUIThemeProvider = ({
  mode = 'light',
  children,
}: PUIThemeProps) => (
  <ThemeProvider theme={createAdvantageTheme(mode)}>{children}</ThemeProvider>
);
