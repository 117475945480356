import Head from 'next/head';

type PageTitleProps = {
  optionalTitle?: string;
};

export function PageTitle({ optionalTitle }: PageTitleProps) {
  const optionalTitleWithDivider = optionalTitle && ` | ${optionalTitle}`;

  return (
    <Head>
      <title>Parade Advantage{optionalTitleWithDivider}</title>
    </Head>
  );
}
