import { useTheme } from '@emotion/react';
import { PUIParadeLogo } from '../../shared-components/PUIParadeLogo';

export type AppHeaderProps = {
  subtitle?: React.ReactNode;
};

export const AppHeader = ({ subtitle }: AppHeaderProps) => {
  const theme = useTheme();

  return (
    <header>
      <h1
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: theme.typography.h2.fontSize,
          marginBottom: 0,
        }}
      >
        <span className="mr1">
          <PUIParadeLogo />
        </span>
        Parade&nbsp;
        <span
          css={{
            color: theme.palette.primary.main,
          }}
        >
          Advantage
        </span>
      </h1>
      <h2
        css={{
          textAlign: 'center',
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.subtitle1.fontWeight,
        }}
      >
        {subtitle}
      </h2>
    </header>
  );
};
