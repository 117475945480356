import { parseJwt } from './parseJwt';

export type UserIdentity = {
  api_token: string;
  company_id: number;
  company_key: string;
  company_user_id?: number | null;
  is_carrier_manager?: boolean | null;
  revenue_code?: string[] | null;
  team_name?: string | null;
  user_email: string;
  user_id: number;
  exp: number;
};

export function getUserIdentityInfo(
  persistedToken: string
): UserIdentity | null {
  return parseJwt(persistedToken) as unknown as UserIdentity;
}
