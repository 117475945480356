import {
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {
  ChangeEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  ReactNode,
  forwardRef,
} from 'react';

export type PUITextFieldProps = {
  id?: string;
  name?: string;
  label?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  value?: string | number | null;
  /** This field should be used for uncontrolled component mode */
  defaultValue?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  // TODO: extract className to a separate interface/type declaration to areuse it in other components;
  className?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  variant?: 'standard' | 'outlined';
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  // TODO: hide the following props from public API of the component if possible.
  InputProps?: TextFieldProps['InputProps'];
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
};

/**
 * Text input field component. The component can be controlled or uncontrolled.
 * @param param0 Component props
 * @returns
 */
export const PUITextField = forwardRef<HTMLInputElement, PUITextFieldProps>(
  function PUITextFieldInternal(
    {
      id,
      name,
      label,
      prefix,
      suffix,
      value,
      defaultValue,
      type = 'text',
      onChange,
      onBlur,
      placeholder,
      className,
      error,
      helperText,
      required,
      variant = 'standard',
      inputComponent,
      InputProps,
      inputProps,
    },
    ref
  ) {
    return (
      <TextField
        ref={ref}
        name={name}
        id={id}
        fullWidth
        label={label}
        value={value}
        defaultValue={defaultValue}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        variant={variant}
        error={error}
        helperText={helperText}
        required={required}
        // margin="normal"
        inputProps={inputProps}
        InputProps={{
          ...InputProps,
          inputComponent,
          ...(prefix && {
            startAdornment: (
              <>
                {InputProps && InputProps.startAdornment}
                <InputAdornment position="start">{prefix}</InputAdornment>
              </>
            ),
          }),
          ...(suffix && {
            endAdornment: (
              <>
                <InputAdornment position="end">{suffix}</InputAdornment>
                {InputProps && InputProps.endAdornment}
              </>
            ),
          }),
        }}
      />
    );
  }
);
