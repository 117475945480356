import AdapterDateFns from '@date-io/date-fns';
import { InputAdornment, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactNode } from 'react';

export interface CustomDatePickerProps {
  value: Date | string | null;
  onChange: (value: Date | string | null) => void;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  disablePast?: boolean;
  addonStart?: ReactNode;
  addonEnd?: ReactNode;
}

/**
 * Date Picker component.
 *
 * **Controlled** component that currently needs a value prop in order to display a selected date.
 * @param param0 Props
 * @returns
 */
export default function PUIDatePicker({
  value,
  onChange,
  helperText,
  fullWidth = false,
  disablePast,
  addonStart,
  addonEnd,
  ...props
}: CustomDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={value}
        disablePast={disablePast}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            variant="standard"
            helperText={helperText}
            {...params}
            fullWidth={fullWidth}
            InputProps={{
              startAdornment: (
                <>
                  {addonStart && (
                    <InputAdornment position="start">
                      {addonStart}
                    </InputAdornment>
                  )}
                </>
              ),
              endAdornment: (
                <>
                  {addonEnd && (
                    <InputAdornment position="end">{addonEnd}</InputAdornment>
                  )}
                  {params.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}
