import { Box, CssBaseline, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PUIAppLayoutProps extends React.PropsWithChildren {}

export const PUIAppLayout = ({ children }: PUIAppLayoutProps) => {
  const theme = useTheme();
  const router = useRouter();
  // this is a fast and hacky way to customize top-level layout based on the current route.
  // this hack can be replaced with one of custom layout component patterns like the following one:
  // https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const isIndexPage = router.pathname === '/';
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          ...(isIndexPage && { backgroundColor: theme.palette.neutral.main }),
        }}
      >
        {children}
      </Box>
    </>
  );
};
