import { FormLabel, FormControlProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type PUIFormLegendProps = PropsWithChildren &
  Pick<FormControlProps, 'className'>;

export const PUIFormLegend = ({
  children,
  ...restProps
}: PUIFormLegendProps) => {
  return (
    <FormLabel
      component="legend"
      sx={(theme) => ({
        fontWeight: theme.typography.subtitle1.fontWeight,
        color: theme.palette.text.primary,
        marginBottom: 2,
      })}
      {...restProps}
    >
      {children}
    </FormLabel>
  );
};
