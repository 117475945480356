export const validateConfig = <T extends Record<string, string | undefined>>(
  config: T
): { [key in keyof T]: string } => {
  let hasErrors = false;
  Object.entries(config).forEach(([key, val]) => {
    if (val === undefined) {
      console.warn(
        `Environment variable "${key}" not found. Please set the variable and try again.`
      );
      hasErrors = true;
    }
  });

  if (hasErrors)
    throw new Error(
      'One or more environment variables are not set. Please check .env file for the appropriate environement.'
    );

  // asserting type of the config here because it's not possible to get undefined value at this point.
  return config as { [key in keyof T]: string };
};
