import { Drawer, DrawerProps, Toolbar } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { PUIDivider } from 'shared-components/PUIDivider';

type PUIDrawerProps = PropsWithChildren & {
  width?: string;
  toolbar?: ReactNode;
  toolbarClassName?: string;
  position?: 'left' | 'right';
  open?: boolean;
  onClose?: () => void;
} & Pick<DrawerProps, 'className'>;

export const PUIDrawer = ({
  children,
  width,
  toolbar,
  toolbarClassName,
  position = 'right',
  open,
  onClose,
  ...restProps
}: PUIDrawerProps) => {
  return (
    <Drawer
      sx={{
        opacity: 1,
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
          boxSizing: 'border-box',
        },
      }}
      open={open}
      onClose={onClose}
      variant={typeof open === 'undefined' ? 'permanent' : 'temporary'}
      anchor={position}
      PaperProps={{
        role: 'dialog',
        'aria-labelledby': 'drawerToolbar',
      }}
      {...restProps}
    >
      {toolbar && (
        <>
          <Toolbar className={toolbarClassName} id="drawerToolbar">
            {toolbar}
          </Toolbar>
          <PUIDivider />
        </>
      )}
      {children}
    </Drawer>
  );
};
