import { PUIImage } from 'shared-components/PUIImage';
import { match } from 'ts-pattern';

const STANDARD_LOGO_SIZE = { width: 45, height: 60 };

type LogoSizeType = 'standard' | 'small';

const getImageSize = (sizeName: LogoSizeType) =>
  match<LogoSizeType>(sizeName)
    .with('standard', () => STANDARD_LOGO_SIZE)
    .with('small', () => ({ width: 36, height: 36 }))
    .otherwise(() => STANDARD_LOGO_SIZE);

export interface PUIParadeLogoProps {
  size?: LogoSizeType;
  inverse?: boolean;
}

// TODO: inline svg as react component to change logo colors based on the selected theme
export const PUIParadeLogo = ({
  size = 'standard',
  inverse = false,
}: PUIParadeLogoProps): JSX.Element => (
  <PUIImage
    src={`/images/parade-logo${inverse ? '-inverse' : ''}.svg`}
    {...getImageSize(size)}
    alt="Parade Logo"
  />
);
