import React, { ReactNode, useCallback, useState } from 'react';
import { match, P } from 'ts-pattern';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  createFilterOptions,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { usePUIDebounceValue } from 'shared-components/usePUIDebounceValueHook';
import { PUIGrid, PUIGridItem } from 'shared-components/PUIGrid';
import {
  PUITextField,
  PUITextFieldProps,
} from 'shared-components/PUITextField';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AutocompleteOptionObject = {
  [key: string]: unknown;
};

// TODO: split ({} | string)[] into ({}[] | string[]) because we should not mix these two type in the same array
export type AutocompleteOption = AutocompleteOptionObject | string;

export type AutocompleteValue<T, Multiple> = Multiple extends true
  ? Array<T>
  : T | null;

const getStringValueFromOption = <T,>(
  optionValue: T,
  defaultKey: string //T extends AutocompleteOptionObject ? keyof T : undefined
) => {
  return match<unknown, string | null>(optionValue)
    .with(P.string, (result) => result)
    .with({ [defaultKey]: P.select() }, (result) => String(result))
    .with(P._, () => null)
    .run();
};

const getReactNodeValueFromOption = <T,>(
  optionValue: T,
  defaultKey: string //T extends AutocompleteOptionObject ? keyof T : undefined
) => {
  return (
    match<unknown, ReactNode | null>(optionValue)
      // .with(P.string, (result) => result)
      .with({ [defaultKey]: P.select() }, (result) => <>{result}</>)
      .with(P._, () => null)
      .run()
  );
};

export const createBasicFilter = createFilterOptions;

export interface PUIFilterOptionsState<T> {
  inputValue: string;
  getOptionLabel: (option: T) => string;
}

export function defaultIsOptionEqualToValue<T>(
  option: T,
  value: T,
  compareBy = 'value'
): boolean {
  const normalizedOption = getStringValueFromOption(option, compareBy);
  const normalizedValue = getStringValueFromOption(value, compareBy);

  return normalizedOption === normalizedValue;
}

export type PUIAutocompleteProps<T, Multiple extends boolean = false> = {
  fullWidth?: boolean;
  multiple?: Multiple;
  variant?: PUITextFieldProps['variant'];
  className?: string;
  filterOptions?: (options: T[], state: PUIFilterOptionsState<T>) => T[];
  getOptionLabel?: (option: T) => string;
  getNewOptionLabel?: (inputValue: string) => string;
  creatable?: boolean;
  getOptionDescription?: (option: T) => ReactNode;
  // labelKey?: T extends AutocompleteOptionObject ? keyof T : never;
  disabled?: boolean;
  inputWidth?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  limitTags?: number;
  useOptionsHook: /**
  Hook for fetching and storing the options. 
  Warning: don't pass different hooks into the same instance of the component without changing the 'key' prop to force re-mounting.
  */
  (input: string) => {
    options: T[];
    isLoading?: boolean;
  };
  debounceDelay?: number;
  onChange?: (value: AutocompleteValue<T, Multiple>) => void;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  value?: AutocompleteValue<T, Multiple>;
  comboboxProps?: Omit<
    React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'color'
  >;
} & Pick<PUITextFieldProps, 'suffix' | 'prefix'>;

export function isNewOption<T>(
  option: T
): option is T & { _newOption: string } {
  if (typeof option === 'object' && option !== null && '_newOption' in option)
    return true;
  return false;
}

// TODO: Autocomplete componet was copied from Capacity UI and its API needs to be re-evaluated for using in Design System.
// This component looks too complex. We need to simplify it.
/**
 * Autocomplete / Combobox component.
 * @param param0 Component props
 * @returns JSX element
 */
export function PUIAutocomplete<T, Multiple extends boolean = false>({
  getOptionLabel: getOptionLabelProp = (option: T) =>
    getStringValueFromOption<T>(option, 'label') ||
    getStringValueFromOption<T>(option, 'value') ||
    '',
  getOptionDescription = (option: T) =>
    getReactNodeValueFromOption<T>(option, 'description') || null,
  getNewOptionLabel = (label: string) => `Add "${label}"`,
  creatable = false,
  // labelKey,
  /**
   * In order to select a new option that doesn't exist in the option list,
   * itemCreator callback should be returning an object that represents the new option.
   * IMPORTANT: Selecting a new non-existing option is not possible for simple string values
   * therefore the type of the option must be an object.
   * @param inputValue - value of the text input
   * @returns
   */
  filterOptions = createBasicFilter(),
  debounceDelay = 400,
  fullWidth = true,
  multiple,
  inputWidth,
  label,
  placeholder,
  limitTags,
  useOptionsHook,
  onChange,
  value,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  variant = 'standard',
  prefix,
  suffix,
  ...props
}: PUIAutocompleteProps<T, Multiple>) {
  const [inputText, setInputText] = useState('');

  const debouncedInputText = usePUIDebounceValue(inputText, debounceDelay);
  const { options, isLoading = false } = useOptionsHook(debouncedInputText);

  const inputChangeHandler = useCallback(
    (_: React.SyntheticEvent, input: string) => {
      setInputText(input);
    },
    []
  );

  const getOptionLabel = useCallback(
    (option: T): string => {
      let result;
      const displayNewOption = creatable && isNewOption(option);
      if (displayNewOption) {
        result = getStringValueFromOption<T>(option, '_newOption') ?? '';
      } else {
        result = getOptionLabelProp(option);
      }
      return result;
    },
    [getOptionLabelProp, creatable]
  );

  const filter = (options: T[], state: PUIFilterOptionsState<T>): T[] => {
    const fiteredOptions = filterOptions(options, state);

    // state.inputValue.trim().length > 0;
    if (creatable) {
      // A hacky but inevitable at the moment way of adding a completely new item to the list of options
      // It works in tandem with isNewOption() function
      fiteredOptions.push({
        _newOption: state.inputValue,
      } as unknown as T);
    }
    return fiteredOptions;
  };

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      autoHighlight={true}
      fullWidth={fullWidth}
      isOptionEqualToValue={isOptionEqualToValue}
      limitTags={limitTags || 2}
      disableClearable={false}
      freeSolo={false}
      disableCloseOnSelect={multiple}
      loading={isLoading}
      onInputChange={inputChangeHandler}
      ChipProps={{ size: 'small' }}
      filterOptions={filter}
      renderOption={(opProps, option, { selected }) => {
        const displayNewOption = creatable && isNewOption(option);
        const description = getOptionDescription(option);
        const labelText = getOptionLabel(option);
        const uniqueId = `${opProps.id ?? ''}${labelText}${
          displayNewOption ? option._newOption && '-new' : ''
        }`;
        return (
          <li {...opProps} key={uniqueId} data-key={uniqueId}>
            <PUIGrid direction="column">
              <PUIGridItem
                css={(theme) =>
                  description && {
                    fontWeight: theme.typography.subtitle1.fontWeight,
                  }
                }
              >
                {multiple && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {displayNewOption ? getNewOptionLabel(labelText) : labelText}
              </PUIGridItem>
              <PUIGridItem>{description}</PUIGridItem>
            </PUIGrid>
          </li>
        );
      }}
      renderInput={(params) => (
        <PUITextField
          {...params}
          prefix={prefix}
          suffix={suffix}
          label={label}
          placeholder={placeholder}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      sx={{ pb: 0.5, display: 'flex', width: inputWidth || 'auto' }}
      onChange={(_, value) => {
        onChange && onChange(value);
      }}
      {...props}
    />
  );
}
