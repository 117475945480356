import { useToggle } from 'components/useToggle';
import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import { setupLaunchDarkly } from './setupLaunchDarkly';
import { PUIDefaultLoader } from 'shared-components';
import { useApiClient } from 'components/ApiClientProvider';

type ComponentWithChildren = ({
  children,
}: {
  children: ReactNode;
}) => JSX.Element;

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const component = useRef<ComponentWithChildren>();
  const isLoaded = useToggle();
  const { session } = useApiClient();
  const userInfo = session.user;

  useEffect(() => {
    void (async () => {
      if (userInfo === null) return;
      try {
        component.current = await setupLaunchDarkly(userInfo.identity);
        isLoaded.toggleOn();
      } catch (error: unknown) {
        console.error('Could not initialize Feature Flags client', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (isLoaded.isOpen && component.current) {
    const Provider = component.current;
    return <Provider>{children}</Provider>;
  } else {
    return <PUIDefaultLoader />;
  }
};
