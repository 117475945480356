import { Client as GatewayClientTypes } from './client';
import OpenAPIClientAxios from 'openapi-client-axios';
import { OpenAPIV3 } from 'openapi-types';
import { CONFIG } from 'config/env.config';
import openApiSchema from './generatedOpenApiSchema/openapi.json';

import {
  HttpClientRequestConfig,
  HttpClientRequestMethod,
} from './HttpClientRequestConfig';

export type HttpClientType = {
  operations: GatewayClientTypes;
  setDefaultHeaders: (
    headers: Record<string, string | number | boolean>
  ) => void;
  getDefaultHeaders: () => Record<string, string | number | boolean>;
  request: <HttpClientReturnDataType>(
    method: HttpClientRequestMethod,
    url: string,
    data?: unknown,
    config?: HttpClientRequestConfig
  ) => Promise<HttpClientReturnDataType>;
};

export const createHttpClient = async (
  schema: OpenAPIV3.Document = openApiSchema as OpenAPIV3.Document
) => {
  const api = new OpenAPIClientAxios({
    quick: true,
    definition: schema,
    axiosConfigDefaults: {
      baseURL: CONFIG.NEXT_PUBLIC_GATEWAY_BASE_URL,
    },
  });

  // OpenAPIClientAxios fetches the OpenAPI schema right here.
  const axiosInstance = await api.init<GatewayClientTypes>();

  return {
    // TODO: we should expose just operation methods instead of the entire axiosInstance.
    operations: axiosInstance,

    /**
     * Sets default HTTP headers
     * @param headers Default set of headers that must be present on every outgoing request
     */
    setDefaultHeaders: (
      headers: Record<string, string | number | boolean>
    ): void => {
      axiosInstance.defaults.headers.common = {
        ...axiosInstance.defaults.headers.common,
        ...headers,
      };
    },

    /**
     * Returns all default HTTP headers
     */
    getDefaultHeaders: (): Record<string, string | number | boolean> => {
      return axiosInstance.defaults.headers.common;
    },

    request: async <HttpClientReturnDataType>(
      method: HttpClientRequestMethod,
      url: string,
      data?: unknown,
      config?: HttpClientRequestConfig
    ) => {
      if (method === 'get') {
        return (
          await axiosInstance[method]<HttpClientReturnDataType>(url, {
            ...(data ? { params: data } : null),
            ...config,
          })
        ).data;
      }

      return (
        await axiosInstance[method]<HttpClientReturnDataType>(url, data, config)
      ).data;
    },
  };
};
