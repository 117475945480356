import { useRouter } from 'next/router';
import { AutocompleteOptionObject } from 'shared-components/PUIAutocomplete';
import parseISO from 'date-fns/parseISO';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useMemo, useRef } from 'react';
import isEqual from 'lodash.isequal';

export interface SearchFormAutocompleteOption extends AutocompleteOptionObject {
  value: string;
  label?: string;
}

export type TopLevelEquipmentType = {
  ACCESSORIALS: Record<string, string>;
  SUBTYPES: Record<string, string>;
  // TODO: either fix the type of the field or use convertArrayToSelectOptions() instead of convertObjectToSelectOptions()
  // LENGTH: string[];
};

export type EquipmentInfo = Record<string, TopLevelEquipmentType>;

export interface SearchParams {
  id: string | null;
  pickup: string | null;
  delivery: string | null;
  date: Date | null;
  equipmentType?: string | null;
  equipmentSubtype?: string[];
  equipmentAccessorials?: string[];
  equipmentExtras?: string[];
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  const currentDateFormatted = currentDate.toISOString();
  return currentDateFormatted;
};

export const getDefaultSearchParams = (): SearchParams => {
  return {
    id: null,
    pickup: null,
    delivery: null,
    date: new Date(),
    equipmentType: null,
    equipmentSubtype: [],
    equipmentAccessorials: [],
    equipmentExtras: [],
  };
};

export const convertObjectToSelectOptions = (
  values?: Record<string, string>
): SearchFormAutocompleteOption[] => {
  if (!values) return [];
  return Object.entries(values).map<SearchFormAutocompleteOption>(
    ([key, val]) => ({
      value: key,
      label: val,
    })
  );
};

export const getEquipmentSubOptions = (
  equipmentInfo: EquipmentInfo | undefined,
  equipmentType: string,
  childKey: keyof EquipmentInfo[string]
): SearchFormAutocompleteOption[] | undefined => {
  if (!equipmentInfo) return;

  if (equipmentType in equipmentInfo) {
    return convertObjectToSelectOptions(equipmentInfo[equipmentType][childKey]);
  }
  return [];
};

export const convertArrayToSelectOptions = (
  values: string[]
): SearchFormAutocompleteOption[] => {
  return values.map<SearchFormAutocompleteOption>((equepmentType) => ({
    value: equepmentType,
  }));
};

export const normalizeStringField = (field: unknown): string | null => {
  if (typeof field === 'string' && field !== '') return field;
  return null;
};

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((val) => typeof val === 'string');
};

export const normalizeArrayField = (field: unknown): string[] => {
  if (isStringArray(field)) return field;
  if (typeof field === 'string') return [field];
  return [];
};

export const parseDateField = (date: unknown): Date | null => {
  if (typeof date === 'string') {
    const parsedDate = parseISO(date);
    return String(parsedDate) === 'Invalid Date' ? null : parsedDate;
  }
  return null;
};

export const serializeDateField = (date: unknown): string | null => {
  if (typeof date === 'string' || date instanceof Date) {
    try {
      return date && new Date(date).toISOString();
    } catch (e) {
      console.warn(
        `could not convert "date" value [${JSON.stringify(date)}] to ISO format`
      );
    }
  }

  return null;
};

export const useSearchId = (searchParams: SearchParams): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _, ...searchParamsWithoutId } = searchParams;
  const prevSearchParams = useRef<Omit<SearchParams, 'id'>>(
    searchParamsWithoutId
  );
  const result = useRef<string | null>(null);
  const isDeeplyEqual = isEqual(
    prevSearchParams.current,
    searchParamsWithoutId
  );

  if (result.current === null || !isDeeplyEqual) {
    result.current = uuidv4();
    prevSearchParams.current = searchParamsWithoutId;
    console.info(
      `generating uuid "${result.current}" for given searchParams`,
      searchParams
    );
  }

  return result.current;
};

export const parseSearchQuery = (
  obj: Record<string, string | string[] | undefined>
): SearchParams => {
  console.log('normalizing obj', { obj });
  return {
    id: null,
    pickup: normalizeStringField(obj['pickup']),
    delivery: normalizeStringField(obj['delivery']),
    // TODO: correct this next line by converting ISO date to internal date format. Check what we actually submit to the gateway...
    date: parseDateField(obj['date']),
    equipmentType: normalizeStringField(obj['equipmentType']),
    equipmentSubtype: normalizeArrayField(obj['equipmentSubtype']),
    equipmentAccessorials: normalizeArrayField(obj['equipmentAccessorials']),
    equipmentExtras: normalizeArrayField(obj['equipmentExtras']),
  };
};

export const cleanupSearchParams = ({
  pickup,
  delivery,
  date,
  equipmentType,
  equipmentSubtype,
  equipmentAccessorials,
  equipmentExtras,
  ...rest
}: SearchParams): SearchParams => {
  return {
    pickup,
    delivery,
    date,
    ...(equipmentType && {
      equipmentType,
    }),
    ...(equipmentSubtype && {
      equipmentSubtype,
    }),
    ...(equipmentAccessorials && {
      equipmentAccessorials,
    }),
    ...(equipmentExtras && {
      equipmentExtras,
    }),
    ...rest,
  };
};

export const useSearchParams = (): [
  SearchParams,
  (params: SearchParams) => void
] => {
  const router = useRouter();
  const searchParams: SearchParams = useMemo(
    () => parseSearchQuery(router.query),
    [router.query]
  );

  searchParams.id = useSearchId(searchParams);

  const setSearchParams = useCallback(
    (searchParameters: SearchParams) => {
      const { id, ...queryObject } = cleanupSearchParams(searchParameters);
      console.log('searchParameters', searchParameters, 'searchId', id);
      router
        .push({
          pathname: '/advantage',
          query: {
            ...queryObject,
            date: serializeDateField(queryObject.date),
          },
        })
        .then(console.info.bind({}, 'searching'))
        .catch(console.info.bind({}, 'could not redirect to search page'));
    },
    [router]
  );

  return [searchParams, setSearchParams];
};
