import { validateConfig } from './configValidator';

// These variables must be set in .env file located in the root directory of the project
const CONFIG_INTERNAL = {
  NEXT_PUBLIC_GATEWAY_BASE_URL: process.env['NEXT_PUBLIC_GATEWAY_BASE_URL'],
  NEXT_PUBLIC_GATEWAY_CODRIVER_BASE_URL:
    process.env['NEXT_PUBLIC_GATEWAY_CODRIVER_BASE_URL'],
  NEXT_PUBLIC_GATEWAY_URL_TO_OPEN_API_SCHEMA:
    process.env['NEXT_PUBLIC_GATEWAY_URL_TO_OPEN_API_SCHEMA'],
  NEXT_PUBLIC_SENTRY_DSN: process.env['NEXT_PUBLIC_SENTRY_DSN'],
  NEXT_PUBLIC_CAPACITY_UI_BASE_URL:
    process.env['NEXT_PUBLIC_CAPACITY_UI_BASE_URL'],
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID:
    process.env['NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID'],
  NEXT_PUBLIC_TARGET_ENVIRONMENT: process.env['NEXT_PUBLIC_TARGET_ENVIRONMENT'],
  NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE:
    process.env['NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE'],
  NEXT_PUBLIC_SENTRY_PROFILES_SAMPLE_RATE:
    process.env['NEXT_PUBLIC_SENTRY_PROFILES_SAMPLE_RATE'],
  PATH_TO_DOWNLOADED_SCHEMA:
    'src/httpClient/generatedOpenApiSchema/openapi.json',
  NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID:
    process.env['NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID'],
};

export const CONFIG = validateConfig(CONFIG_INTERNAL);
