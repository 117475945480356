import { Popover, PopoverProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export type PUIPopoverProps = PropsWithChildren & {
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
  };
  onClose?: PopoverProps['onClose'];
  anchorEl?: PopoverProps['anchorEl'];
  className?: string;
};

export const PUIPopover = ({
  children,
  onClose,
  anchorEl,
  anchorOrigin,
  className,
  ...restProps
}: PUIPopoverProps) => {
  return (
    <Popover
      className={className}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      {...restProps}
    >
      {children}
    </Popover>
  );
};
