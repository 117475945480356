import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useApiClient } from 'components/ApiClientProvider';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { PageTitle } from 'components/PageTitle';
import { Components } from 'httpClient/client';
import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { PUICardSimple } from 'shared-components';
import { PUIAlert } from 'shared-components/PUIAlert';
import { PUIButton } from 'shared-components/PUIButton';
import { PUIForm } from 'shared-components/PUIForm';
import { PUIFormLegend } from 'shared-components/PUIFormLegend';
import { PUIGrid, PUIGridItem } from 'shared-components/PUIGrid';
import { PUITextField } from 'shared-components/PUITextField';

type AuthGuardProps = PropsWithChildren<{
  onSuccess?: () => void;
}>;

/**
 * This compoenent wraps component trees that require authentication.
 * It renders a login form until it gets an access token from the back end.
 * @param param0 doesn't accept any props other than a children prop
 * @returns
 */
export const AuthGuard = ({ onSuccess, children }: AuthGuardProps) => {
  const { gatewayClient, session } = useApiClient();
  const accessToken = session.user.accessToken;

  const {
    mutate: submit,
    isError,
    error: loginError,
    isLoading: isLoggingIn,
  } = useMutation<
    AxiosResponse<Components.Schemas.APITokenResponse>,
    AxiosError<Components.Schemas.HTTPError>,
    Components.Schemas.CredentialsRequest
  >(
    ['login'],
    async ({ username, password }) =>
      gatewayClient.operations.jwt_jwt_post(null, {
        username,
        password,
      }),
    {
      onSuccess: (response) => {
        session.setAuthToken(response.data.jwt);
        onSuccess?.();
      },
    }
  );

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const submitHandler = handleSubmit(({ username, password }) => {
    if (formState.isValid && username && password) {
      submit({ username, password });
    }
  });

  // Skip rendering children if accessToken is null or undefined
  if (accessToken) return <>{children}</>;

  return (
    <>
      <PageTitle optionalTitle="Login" />
      <AppHeader
        subtitle={
          <>
            Find your price advantage on any lane <br />
            and win more customer loads.
          </>
        }
      />
      <PUIGrid justifyContent="center" className="mt4">
        <PUIGridItem xs={16} sm={8} md={6} xl={4}>
          <PUICardSimple size="large">
            <PUIForm
              onSubmit={(e) => {
                void submitHandler(e);
              }}
            >
              <PUIFormLegend>Login</PUIFormLegend>

              <PUIAlert
                type="info"
                open={session.user.loggedOut}
                className="mb2"
              >
                You have been logged out. Please login again.
              </PUIAlert>

              <PUIAlert type="error" open={isError} className="mb2">
                {loginError?.response?.data?.detail ||
                  'Unknown authentication error'}
              </PUIAlert>

              <PUITextField
                label="Email"
                required
                className="mb2"
                {...register('username', { required: true })}
              />
              <PUITextField
                label="Password"
                type="password"
                required
                {...register('password', { required: true })}
                className="mb2"
              />

              <PUIButton
                variant="contained"
                disabled={!formState.isValid || isLoggingIn}
                type="submit"
                css={(theme) => ({
                  display: 'block',
                  marginTop: theme.spacing(4),
                  width: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                })}
              >
                LOGIN
              </PUIButton>
            </PUIForm>
          </PUICardSimple>
        </PUIGridItem>
      </PUIGrid>
    </>
  );
};
