import {
  createTheme,
  PaletteOptions,
  Theme as MaterialTheme,
} from '@mui/material';
import '@emotion/react';

export const PUIPaletteMode = ['light', 'dark'] as const;
export type PUIPaletteMode = typeof PUIPaletteMode[number];
export type PUITheme = MaterialTheme;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends PUITheme {}
}
declare module '@mui/material/styles' {
  interface Palette {
    optional: Palette['primary'];
    neutral: Palette['primary'];
    contrast: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    contrast: PaletteOptions['primary'];
  }
  export interface TypeBackground {
    shadow: string;
    attention: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    contrast: true;
  }
}

/*
______                       _         _   _  _____  ______            _                 _____              _                   
| ___ \                     | |       | | | ||_   _| |  _  \          (_)               /  ___|            | |                  
| |_/ /__ _  _ __  __ _   __| |  ___  | | | |  | |   | | | | ___  ___  _   __ _  _ __   \ `--.  _   _  ___ | |_  ___  _ __ ___  
|  __// _` || '__|/ _` | / _` | / _ \ | | | |  | |   | | | |/ _ \/ __|| | / _` || '_ \   `--. \| | | |/ __|| __|/ _ \| '_ ` _ \ 
| |  | (_| || |  | (_| || (_| ||  __/ | |_| | _| |_  | |/ /|  __/\__ \| || (_| || | | | /\__/ /| |_| |\__ \| |_|  __/| | | | | |
\_|   \__,_||_|   \__,_| \__,_| \___|  \___/  \___/  |___/  \___||___/|_| \__, ||_| |_| \____/  \__, ||___/ \__|\___||_| |_| |_|
                                                                           __/ |                 __/ |                          
                                                                          |___/                 |___/                           

      *-------------------------------------------------------------------------------------------------*
      | This is the main theme of the PUI Design System and it's based on MUI library theme.            |
      | We try to limit the changes to this theme with only design token values to make it easier       |
      | to replace MUI with another framework OR completely custom implementation of base components.   |
      | Please, try to avoid using MUI-specific styles (styleOverrides) in this theme.                  |
      *-------------------------------------------------------------------------------------------------*
*/

export const GLOBAL_PALETTE = {
  blue: {
    bright: '#1F4FFF',
    dark: '#183CC2',
    dark_30: '#183CC24D',
    bright_30: '#1F4FFF4D',
  },
  red: {
    bright: '#FB1A34',
    dark: '#CC152A',
    dark_30: '#EEB8BE',
    bright_30: '#FDB9C1',
  },
  green: {
    bright: '#00FF99',
    dark: '#00C777',
    dark_30: '#B1EDD5',
    bright_30: '#B1FEDF',
  },
  grayscale: {
    black: '#0D0D0D',
    dark: '#7C7E80',
    medium: '#C9CDD1',
    light: '#EAEDF0',
    ultraLight: '#F4F7FA',
    nearWhite: '#FDFDFD',
    white: '#FFFFFF',
  },
} as const;

export type GlobalPaletteType = Readonly<typeof GLOBAL_PALETTE>;

export const lightThemePalette: PaletteOptions = {
  primary: {
    main: GLOBAL_PALETTE.blue.bright,
  },
  secondary: {
    main: GLOBAL_PALETTE.grayscale.black,
  },
  success: {
    light: GLOBAL_PALETTE.green.bright_30,
    main: GLOBAL_PALETTE.green.bright,
    dark: GLOBAL_PALETTE.green.dark,
  },
  neutral: {
    // TODO: add main, dark, and contrastText variants.
    main: GLOBAL_PALETTE.grayscale.light, // set main to the same value as light to make MUI types happy (main varian is required).
    light: GLOBAL_PALETTE.grayscale.ultraLight,
    dark: GLOBAL_PALETTE.blue.bright_30,
  },
  contrast: {
    main: '#000000',
    contrastText: '#FFFFFF',
  },
  error: {
    light: GLOBAL_PALETTE.red.bright_30,
    main: GLOBAL_PALETTE.red.bright,
    dark: GLOBAL_PALETTE.red.dark,
  },
  warning: {
    main: `#ff8513`,
  },
  info: {
    main: GLOBAL_PALETTE.grayscale.nearWhite,
  },
  background: {
    default: GLOBAL_PALETTE.grayscale.nearWhite,
    paper: GLOBAL_PALETTE.grayscale.white,
    shadow: GLOBAL_PALETTE.grayscale.light,
    attention: GLOBAL_PALETTE.grayscale.black,
  },
  divider: GLOBAL_PALETTE.grayscale.light,
};

export const darkThemePalette: PaletteOptions = {
  primary: {
    main: `#007AFF`,
  },
  secondary: {
    main: GLOBAL_PALETTE.grayscale.dark,
  },
  success: {
    light: GLOBAL_PALETTE.green.bright,
    main: GLOBAL_PALETTE.green.dark,
    dark: GLOBAL_PALETTE.grayscale.dark,
  },
  neutral: {
    // TODO: add main, dark, and contrastText variants.
    main: '#B2BBC7', // set main to the same value as light to make MUI types happy (main varian is required).
    light: '#B2BBC7',
  },
  contrast: {
    main: '#ffffff',
  },
  error: {
    main: GLOBAL_PALETTE.red.bright,
    dark: GLOBAL_PALETTE.red.dark,
  },
  warning: {
    main: `#ff8513`,
  },
  info: {
    main: GLOBAL_PALETTE.grayscale.black,
  },
  background: {
    default: `#151212`,
    paper: `#222222`,
    shadow: GLOBAL_PALETTE.grayscale.light,
    attention: GLOBAL_PALETTE.grayscale.white,
  },
  divider: GLOBAL_PALETTE.grayscale.light,
};

export const typography = {
  fontFamily: `'Untitled Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  h1: {
    fontWeight: 500,
    fontSize: `2.986rem`,
    lineHeight: `4rem`,
  },
  h2: {
    fontWeight: 500,
    fontSize: `2.488rem`,
    lineHeight: `3rem`,
  },
  h3: {
    fontWeight: 500,
    fontSize: `2.074rem`,
    lineHeight: `2.5rem`,
  },
  h4: {
    fontWeight: 500,
    fontSize: `1.728rem`,
    lineHeight: `2rem`,
  },
  h5: {
    fontWeight: 500,
    fontSize: `1.44rem`,
    lineHeight: `1.75rem`,
  },
  h6: {
    fontWeight: 500,
    fontSize: `1.2rem`,
    lineHeight: `1.5rem`,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: `1rem`,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: `0.833rem`,
  },
  body1: {
    fontWeight: 400,
    fontSize: `1rem`,
  },
  body2: {
    fontWeight: 400,
    fontSize: `0.833rem`,
  },
};

export const createAdvantageTheme = (mode: PUIPaletteMode): PUITheme => {
  return createTheme({
    spacing: 4,
    palette: {
      mode,
      ...(mode === `light`
        ? {
            ...lightThemePalette,
          }
        : {
            ...darkThemePalette,
          }),
    },
    typography,
  });
};
