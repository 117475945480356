import { CircularProgress } from '@mui/material';
import { css } from '@emotion/react';

// TODO: make size and position configurable

export const PUIDefaultLoader = () => (
  <div
    css={css`
      display: flex;
      height: 100vh;
      width: 100%;
      justify-items: center;
      align-items: center;
    `}
    aria-label="Loading..."
  >
    <CircularProgress size="5rem" color="primary" sx={{ margin: 'auto' }} />
  </div>
);
