import { Grid, GridProps } from '@mui/material';
import { HTMLAttributes } from 'react';

export type PUIGridItemProps = React.PropsWithChildren &
  // Props from MUI library
  Pick<GridProps, 'lg' | 'md' | 'sm' | 'xl' | 'xs' | 'className'> &
  HTMLAttributes<HTMLDivElement>;

export const PUIGridItem = ({ children, ...rest }: PUIGridItemProps) => {
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
};
