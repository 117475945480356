import { Grid } from '@mui/material';
import { GridProps } from '@mui/material';
import { StandardLonghandPropertiesFallback } from 'csstype';
import React from 'react';

export type PUIGridProps = React.PropsWithChildren &
  // Props from MUI library
  Pick<GridProps, 'columns' | 'direction' | 'spacing' | 'className'> &
  // CSS Properties
  Pick<StandardLonghandPropertiesFallback, 'alignItems' | 'justifyContent'>;

export const PUIGrid = ({
  children,
  columns = 16,
  alignItems,
  justifyContent,
  direction,
  spacing,
  className,
}: PUIGridProps) => {
  return (
    <Grid
      container
      columns={columns}
      alignItems={alignItems}
      justifyContent={justifyContent}
      direction={direction}
      spacing={spacing}
      className={className}
    >
      {children}
    </Grid>
  );
};
