import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useFetchMessage } from 'components/useFetchMessage';
import { PropsWithChildren, useMemo } from 'react';
import { isAxiosAuthorizationError } from 'shared-components';

const hasMessageInResponse = (
  data: unknown
): data is { message: string; message_type: string } => {
  return data instanceof Object && 'message' in data && 'message_type' in data;
};

const MS_IN_SEC = 1000;
const DATA_STALE_AFTER_SEC = 3;
/**
 * This wrapper is built on top of react-query's QueryClientProvider and exposes the library
 * @param param0 Children prop
 * @returns JSX.Element
 */
export const FetchClientProvider = ({ children }: PropsWithChildren) => {
  const showFetchMessage = useFetchMessage();
  const MAX_RETRY_ATTEMPTS = 3;

  const queryCache = useMemo(
    () =>
      new QueryCache({
        // These are the global options for all queries. They cannot be overridden by individual queries.
        onSuccess: (data) => {
          if (hasMessageInResponse(data) && data.message_type !== 'success') {
            showFetchMessage(data.message, data.message_type);
          }
        },
      }),
    [showFetchMessage]
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: unknown) => {
              const hasRetryAttempts = failureCount < MAX_RETRY_ATTEMPTS;
              if (error instanceof AxiosError) {
                const isAuthError = isAxiosAuthorizationError(error);
                const shouldRetry = hasRetryAttempts && !isAuthError;
                const requestURL = error.config?.url;
                console.info(
                  `Request failure for "${String(requestURL)}".
                Failure #${failureCount} < ${MAX_RETRY_ATTEMPTS}. 
                Authentication error?: ${isAuthError ? 'yes' : 'no'}.
                Should retry?: ${shouldRetry ? 'yes' : 'no'}.`,
                  error
                );
                return shouldRetry;
              }

              console.info(
                `Request failure #${failureCount} < ${MAX_RETRY_ATTEMPTS}.
              Should retry?: ${hasRetryAttempts ? 'yes' : 'no'}.`,
                error
              );
              return hasRetryAttempts;
            },
            // suspense: true,
            refetchOnWindowFocus: false,
            // enable error boundaries support once we wrap all components that consume network data into fallback components.
            useErrorBoundary: false,
            staleTime: DATA_STALE_AFTER_SEC * MS_IN_SEC,
          },
        },
      }),
    [queryCache]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
